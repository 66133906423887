<template>
	<div
		class="category"
	>
		<template v-if="!processing">
			<dropdown-component
				v-if="!mobile"
				:list="allCategoriesList"
				:active="selectedCategory"
				@handle-click="selectCategory"
				:screen_limit="screen_limit"
			/>
			<div v-if="mobile" class="mobile-category-list">
				<vod-categories-item-mobile
					v-for="category in allCategoriesList"
					:key="category.id"
					:category-info=category
					@category-selected="selectCategory"
					:selectedCategory="selectedCategory"
					:class="{
                          selected: Number(category.id) === Number(selectedCategory)
                        }"
				/>
			</div>
		</template>
		<Loader v-else/>
	</div>
</template>

<script>
  import {mapActions} from "vuex";
  import Loader from "../../../components/Loader";
  import {URL_QUERIES, ALL_CATEGORY} from "../../../const";
  import VodCategoriesItemMobile from "./VodCategoriesItemMobile";
  import DropdownComponent from "../../../components/base-components/dropdown/DropdownComponent";



  export default {
    name: "VodCategories",
    components: {DropdownComponent, VodCategoriesItemMobile, Loader},
    props: {
      mobile: {
        type: Boolean
      },
      screen_limit: {
        type: Number,
        default: 6
      }
    },
    data() {
      return {
        allCategories: [],
        processing: false,
        selectedCategory: null,
      }
    },
    methods: {
      ...mapActions(['GET_VOD_CATEGORIES']),
      updateRoute(id) {
        const query = new URLSearchParams(this.$route.query)
        if (Number(query.get(URL_QUERIES.CATEGORY)) === Number(id)){
          return;
        }
        query.set(URL_QUERIES.CATEGORY, id)
        const newQuery = Object.fromEntries(query.entries());
        this.$router.push({
          ...this.$route,
          query: newQuery
        })
      },
      selectCategory(category) {
        this.selectedCategory = category.id
        this.$emit('category-selected', category)
        this.updateRoute(category.id)
      },

      arrayFromMap(map) {
        return map.size ? Array.from(map.values()) : map
      },
      reduceSubtree(subtree) {
        return this.arrayFromMap(subtree)
            .map(e => {
              e?.subtree && (e.subtree = this.reduceSubtree(e?.subtree));
              return e
            })
      },
      prepareCategoriesTree(categoriesList) {
        const mapAll = new Map(categoriesList.map(i => [Number(i.id), i]));
        categoriesList.forEach((item) => {
          if (item.parent_id) {
            const parent = mapAll.get(Number(item.parent_id));
            if (!Object.prototype.hasOwnProperty.call(parent, 'subtree')) {
              parent.subtree = new Map()
            }
            parent.subtree.set(item.id, item);
          }
        })
        mapAll.forEach((value, key) => {
          Number(value.parent_id) !== ALL_CATEGORY.parent_id && mapAll.delete(key)
        })

        return Array.from(mapAll.values()).map((c) => {
          if (c.subtree) {
            c.subtree = this.reduceSubtree(c.subtree)
          }
          return c
        })
      },
    },

    computed: {
      allCategoriesList() {
        return this.prepareCategoriesTree([ALL_CATEGORY, ...this.allCategories])
      },

    },

    mounted() {
      this.processing = true;
      this.GET_VOD_CATEGORIES({limit: 100})
          .then((data) => {
            this.allCategories = data.data;
            const queryId = (new URLSearchParams(this.$route.query))?.get(URL_QUERIES.CATEGORY);
            if (queryId) {
              const category = data.data.find(({id}) => Number(id) === Number(queryId))
              category && this.selectCategory(category)
            }
          })
          .finally(() => {
            this.processing = false
          })
    }
  }
</script>

<style scoped lang="scss">
	@import "../../../assets/css/colors";

	.category {
		display: flex;
		gap: .3rem;
	}

	.mobile-category-list {
		flex-grow: 1;
		text-align: left;
		max-height: 100vh;
		overflow-y: auto;
		&::-webkit-scrollbar{
			width: 6px;
		}
		&::-webkit-scrollbar-thumb{
			background: $accent-warning;
			border-radius: 10px;
		}

		& > div {
			max-width: 100%;
		}
	}




</style>
<template>
  <div class="dropdown-wrapper" ref="wrapper">
    <div class="main-list">
      <div
        v-for="item of displayedMainItems"
        :key="item.id"
        class="main-item"
        @click="handleClick(item)"
        :class="{
          opened: dropdownOpened && dropdownOpened.id === item.id,
          selected: item.id === active,
        }"
      >
        <span>{{ item.title }}</span>
        <button-component
          variant="icon"
          v-if="item.subtree"
          class="arrow"
          @btn-click="toggleDropdown(item)"
        >
          <single-angle />
        </button-component>
      </div>
      <button-component
        v-if="hiddenMainItems.length"
        variant="transparent"
        class="more-button"
        @btn-click="toggleMore"
        >{{ $t("More") }}...
      </button-component>
    </div>
    <div v-show="dropdownOpened" class="dropdown-content-wrapper">
      <div class="dropdown-content" @click.stop>
        <div class="dropdown-navigation">
          <button-component
            variant="icon"
            class="close-dropdown-button"
            @btn-click="closeDropdown"
          >
            <x-mark-icon />
          </button-component>
        </div>
        <div class="dropdown-subtrees">
          <div
            v-for="(subtree, index) in openedSubtrees"
            :key="index"
            class="dropdown-list"
          >
            <div
              v-for="item in subtree"
              :key="item.id"
              class="subtree-item"
              @click.stop="selectCategory(item)"
            >
              <span>{{ item.title }}</span>
              <button-component
                variant="icon"
                v-if="item.subtree"
                class="subtree-arrow"
                @btn-click="toggleSubtree(item)"
              >
                <single-angle />
              </button-component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "../buttons/ButtonComponent";
import SingleAngle from "../../../assets/img/singleAngle";
import XMarkIcon from "../../../assets/img/XMarkIcon";

export default {
  name: "DropdownComponent",
  components: { XMarkIcon, SingleAngle, ButtonComponent },
  props: {
    list: {
      type: Array,
    },
    active: {
      type: Number,
    },
    screen_limit: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {
      dropdownOpened: null,
      currentActive: this.active,
    };
  },
  watch: {
    active() {
      this.currentActive = this.active;
    },
  },
  methods: {
    openDropdown(item) {
      this.dropdownOpened = item;
    },
    closeDropdown() {
      this.dropdownOpened = null;
    },
    toggleDropdown(item) {
      item === this.dropdownOpened
        ? this.closeDropdown()
        : this.openDropdown(item || true);
    },
    toggleSubtree(item) {
      const subtreeExist = this.openedSubtrees.find((tree) => tree === item.subtree);
      this.currentActive = subtreeExist ? item.parent_id : item.id;
    },
    selectCategory(category) {
      this.$emit("handle-click", category);
      !category.subtree && this.closeDropdown();
    },
    handleClick(item) {
      this.selectCategory(item);
      this.closeDropdown();
    },
    findCategoryInSubtree(subtree, id, onTrueAction) {
      if (!subtree) return false;
      return subtree.find((category) => {
        const exist =
          Number(category.id) === Number(id) ||
          (category.subtree &&
            this.findCategoryInSubtree(category.subtree, id, onTrueAction));
        exist && onTrueAction?.(category);
        return exist;
      });
    },
    toggleMore() {
      this.toggleDropdown({
        subtree: this.hiddenMainItems,
      });
    },
  },
  computed: {
    openedSubtrees() {
      if (!this.dropdownOpened) {
        return null;
      }
      const categoriesTree = [],
        subtrees = [],
        dropdownSubtree = this.dropdownOpened?.subtree;

      this.findCategoryInSubtree(dropdownSubtree, this.currentActive, (i) =>
        categoriesTree.push(i)
      );
      categoriesTree.push(this.dropdownOpened);
      categoriesTree.reverse().forEach((item) => {
        item.subtree && subtrees.push(item.subtree);
      });
      return subtrees;
    },
    displayedMainItems() {
      return this.list.slice(0, this.screen_limit);
    },
    hiddenMainItems() {
      return this.list.slice(this.screen_limit);
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/css/colors";

.dropdown-wrapper {
  --dropdown-item-line-height: 1.5rem;
  --dropdown-item-padding-tb: 1rem;
  --dropdown-item-padding-lr: 2rem;
  --max-display-item: 5;
  position: relative;
  z-index: 10;
}

.main-list {
  display: flex;
  gap: 1rem;
  align-items: center;
  border-radius: 0.4rem;
  overflow: hidden;
  box-sizing: border-box;
}

.selected {
  border-bottom: 2px solid $accent-warning;
}

.main-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 1rem;
  width: 100%;
  max-width: 15rem;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.opened {
    .arrow {
      transform: rotate(90deg);
    }
  }
}

.more-button {
  background: transparent;
  color: $text-primary;
}

.arrow,
.subtree-arrow {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.2rem;
  color: $main-background;
  transform: rotate(-90deg);
  transition: all 0.3s linear;
}

.dropdown-content-wrapper {
  position: absolute;
  display: flex;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  width: 100%;
  z-index: -1;
  justify-content: center;
  align-items: center;
}

.dropdown-content {
  max-width: 95vw;
  width: auto;
  background: rgba($text-secondary-rgb, 0.85);
  backdrop-filter: blur(2rem);
  border: 1px solid $text-tertiary-light;
  box-shadow: 0 0 0.3rem $text-tertiary-light, 0 0 1rem $text-tertiary-light;
}

.dropdown-navigation {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: -2.5rem;
  z-index: 10;
}

.close-dropdown-button {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  color: $main-background;
}

.dropdown-subtrees {
  display: flex;
  gap: 2rem;
  height: 40vh;
  overflow-x: auto;
  max-height: calc(
    var(--max-display-item) *
      (var(--dropdown-item-padding-tb) * 2 + var(--dropdown-item-line-height))
  );
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $accent-warning;
    border-radius: 10px;
  }
}

.dropdown-list {
  max-width: 15rem;
  min-width: 15rem;
  overflow-y: auto;
  text-align: left;
  flex-grow: 1;
  border-left: 2px solid $text-tertiary-light;

  &:first-child {
    border-left: none;
  }

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $accent-warning;
    border-radius: 10px;
  }
}

.subtree-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba($accent-warning-rgb, 0);
  transition: background-color 0.3s linear;

  &:hover {
    background-color: rgba($accent-warning-rgb, 0.5);
  }

  span {
    padding: var(--dropdown-item-padding-tb) var(--dropdown-item-padding-lr);
    line-height: var(--dropdown-item-line-height);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.subtree-arrow {
  transform: rotate(-180deg);
}
</style>
